export default {
    name: "FAQ",
    components: {
        
    },
    data: () => {
        return {
            activeNames: 0,
            ative: 0,
        }
    },
    watch: {
		$route: "fetchData"
    },
    created() {

    },
    mounted() {
    },
    computed: {

    },
    methods: {
        fetchData() {
            this.$forceUpdate();
        },
        ativeClick(val) {
            this.ative = val;
            this.activeNames = 0;
        }
    }
}
