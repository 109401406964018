<template>
    <div class="FAQ">
        <div class="warp">
            <div class="faq-main">
                <div class="img">
                    <el-image :src="require('@/assets/images/index/component/tittle.png')" fit="cover" style="cursor: pointer;height: 100%;"></el-image>
                </div>
                <div class="main-tab">
                    <div :class="ative == 0 ? 'item itemBack' : 'item'" @click="ativeClick(0)">
                        <div class="img">
                            <el-image :src="ative == 0 ? require('@/assets/images/index/component/Shipping1.png') : require('@/assets/images/index/component/Shipping.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Shipping</span>
                    </div>
                    <div :class="ative == 1 ? 'item itemBack' : 'item'" @click="ativeClick(1)">
                        <div class="img">
                            <el-image :src="ative == 1 ? require('@/assets/images/index/component/orderIssuse1.png') : require('@/assets/images/index/component/orderIssuse.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Order Issuse</span>
                    </div>
                    <div :class="ative == 2 ? 'item itemBack' : 'item'" @click="ativeClick(2)">
                        <div class="img">
                            <el-image :src="ative == 2 ? require('@/assets/images/index/component/Account1.png') : require('@/assets/images/index/component/Account.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Account</span>
                    </div>
                    <div :class="ative == 3 ? 'item itemBack' : 'item'" @click="ativeClick(3)">
                        <div class="img">
                            <el-image :src="ative == 3 ? require('@/assets/images/index/component/Return1.png') : require('@/assets/images/index/component/Return.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Return & Exchange</span>
                    </div>
                    <div :class="ative == 4 ? 'item itemBack' : 'item'" @click="ativeClick(4)">
                        <div class="img">
                            <el-image :src="ative == 4 ? require('@/assets/images/index/component/Payment1.png') : require('@/assets/images/index/component/Payment.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Payment</span>
                    </div>
                    <div :class="ative == 5 ? 'item itemBack' : 'item'" @click="ativeClick(5)">
                        <div class="img">
                            <el-image :src="ative == 5 ? require('@/assets/images/index/component/memberCenter1.png') : require('@/assets/images/index/component/memberCenter.png')" fit="cover" style="cursor: pointer;"></el-image>
                        </div>
                        <span>Member Center</span>
                    </div>
                </div>
                <div class="main-collapse">
                    <div class="item" v-show="ative == 0">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="1. Do you ship to my country?" name="1">
                                <div class="text">
                                    <p>YES! We ship orders all over the world, even if your country is not listed in our system. If you want to know more detailed information, please check the dedicated</p>
                                    <p>“<a><u>Shipping Info</u></a>”.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="2. How much is the shipping cost?" name="2">
                                <div class="text">
                                    <p>Please <a><u>click here</u></a> to check out our shipping cost to different countries. The cost varies from country to country. There are no hidden shipping cost when you checkout. We do not base our shipping cost on weight, but rather have a standard cost for each order under $99. We provide free expedite shipping globally for orders over $99. There may be discounts or promotions which lowers shipping cost, please stay informed! For some countries, expedite shipping is available along with standard shipping. Our advice is to log in to your account, add the items to your cart and then view the shipping fee which will be automatically calculated and displayed.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="3. How do I know if the item has free shipping?" name="3">
                                <div class="text">
                                    <p>You can enjoy free express shipping on all orders over US$99. You can refer to our Shipping Info for more details. There are also monthly or weekly promotions with free shipping, please stay updated with us!</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="4. How long will it take to receive my order?" name="4">
                                <div class="text">
                                    <p>Please note the total time = processing time + shipping time (this does not include any customs delays, weekends, holiday periods and any unforeseen circumstances.)</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="5. How can I track my order?" name="5">
                                <div class="text">
                                    <p>We will send you an email with the corresponding delivery information once your order is ready to ship. After that you will be able to track your order under “My Orders” when you log in to your SOYEGO account. </p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="item" v-show="ative == 1">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="1. How do I place my order?" name="1">
                                <div class="text">
                                    <p>Ordering online from SOYEGO is seamless! When viewing an product, simply select the size, color and quantity you want and then click ‘ADD TO BAG’ (or click ‘BUY’ for FAST checkout). Once you have your all your desired products in your BAG, simply click the ‘PROCEED TO CHECKOUT’ button. You will then be prompted to either sign in to your account or to create a new account. Next, enter your shipping address and enter any discount coupon codes you may want to use. Finally, press ‘SUBMIT’ to finalize the order and pay. You may opt to pay by Credit/Debit card or by <b>Paypal</b></p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="2. Can I order by phone?" name="2">
                                <div class="text">
                                    <p>Yes, you can visit our mobile website on your smartphone by simply entering <a><u>http://m.soyego.com/ </u></a> on the search bar of your browse; or you can download our iOS/Android app. You can find our app on Apple APP store or Google play.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="3. How long can items be stored for in my shopping bag?" name="3">
                                <div class="text">
                                    <p>The shopping bag will retain its contents forever! This applies to all customers.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="4. An item added to my shopping bag was sold out." name="4">
                                <div class="text">
                                    <p>Product is only reserved once your purchase is completed during the checkout process. In case of no inventory available, your product will not be added to your checkout process. Stock availability cannot always be guaranteed, you are therefore encouraged to submit payment as soon as possible.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="5. Do you restock items that are sold out?" name="5">
                                <div class="text">
                                    <p>YES! We restock most items that are sold out ASAP. However, due to demand and supply chain constraints, it usually takes 5 to 7 days to restock. Some off season and previous season products are unlisted in our offerings when popularity declines. SOYEGO tries to provides as much product to our customer as possible.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="6. How do I know what size to choose?" name="6">
                                <div class="text">
                                    <p>You can check the Sizes Guides on the product page and view our Size Charts for easy reference.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="7. Can I cancel/make changes to the order I just placed?" name="7">
                                <div class="text">
                                    <p>It is only possible to make a change to your order before we process your order, usually within an hour. We process orders very quickly, so changes and cancellations cannot always be fulfilled. You must immediately contact our Customer Care team (within the hour) with your change/cancellation request details included.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="item" v-show="ative == 2">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="1. How do I register?" name="1">
                                <div class="text">
                                    <p>You can easily create your own SOYEGO account by clicking here. Alternatively, you may also click “Me” in the right bottom corner of the home page, which will direct you to the login page where you can click “Login” and sign up. Please make sure that your password is at least 6 characters in length. You may login to your account using your Email address and password afterwards.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="2. I cannot login to my account." name="2">
                                <div class="text">
                                    <p>Please check your login details carefully, making sure that the Email address and the password is the same one you used for registration. If you are still not able to access your account, please contact -<a><u>help@soyego.com.</u></a></p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="3. I forgot my password." name="3">
                                <div class="text">
                                    <p>If you forgot your password, please click the "Forgot your password?" link in the “sign in” page, we will send you an email with information on how to reset password.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="4. How can I change my password?" name="4">
                                <div class="text">
                                    <p>Log in to your account. In the “Me” page, click on your Email address and click the “Change Password ” option.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="5. Can I change my email address into another one?" name="5">
                                <div class="text">
                                    <p>You may send an Email to help@soyego.com, we will send you an Email with instructions where identification materials are needed. Please note that we do not recommend changing Email address due to privacy protection.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="6. What is the difference between the billing address and the shipping address?" name="6">
                                <div class="text">
                                    <p>The billing address is the same address you provided to your bank when you applied for your credit/debit card; the shipping address is the one you want us to ship the order to. The shipping address and the billing address maybe different.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="item" v-show="ative == 3">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="How do I return or exchange an item?" name="1">
                                <div class="text">
                                    <p>Please <a><u>click here</u></a>  to checkout our return policy. We accept returns on most orders. Simply click “Return” in your “My Order” page and follow the instructions to request a return or exchange. We recommend that all returns be sent via a traceable carrier. Keep the Return Tracking Number associated with the package you are returning to ensure that the package is returned to our warehouse. </p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="item" v-show="ative == 4">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="1. What kinds of payment are accepted?" name="1">
                                <div class="text">
                                    <p>We accept the following forms of secure payment: </p>
                                    <p>(1) Credit card/Debit card</p>
                                    <p>(2) PayPal</p>
                                    <p>(3) Credit card via Paypal (Visa, MasterCard, Discover, American Express, etc.)</p>
                                    <p>(4) Wired Transfer (Please contact customer service <a><u>help@soyego.com</u></a> for with order detail)</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="2. What currencies can I pay?" name="2">
                                <div class="text">
                                    <p>Technically, we accept any currencies as long as it is traded globally. Please fill in your debit/credit card details without worrying about currency. </p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="3. Is my privacy and personal information secure on your site?" name="3">
                                <div class="text">
                                    <p>We have a no sharing policy, all your infos will not be shared to any third parties. Please click here to view the full details on how we protect your privacy and personal information.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="4. How long will it take to receive my order?" name="4">
                                <div class="text">
                                    <p>Please note the total time = processing time + shipping time (this does not include any customs delays, weekends, holiday periods and any unforeseen circumstances.)</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="5. Will I have to pay any sales tax?" name="5">
                                <div class="text">
                                    <p>Tax is charged by Customs offices in destination country based on their rules and policies. customer is responsible to find out the situation in their country. Shipments from all of our warehouses are delivery duty unpaid. Therefore, If any, these charges must be paid by the customers. Customers take full liability for all return shipment costs, customs charges and handling fees should they refuse to accept a parcel due to import duties or taxes. Please note that according to customs regulations, SOYEGO cannot declare purchased items as a gift or list an amount that lower than the actual product price.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="item" v-show="ative == 5">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="1. How do I get coupons?" name="1">
                                <div class="text">
                                    <p>Register for a customer account on SOYEGO and enjoy new members coupon upon sign up. Some special activities will also feature coupon giveaways. </p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="2. How do I use my coupons?" name="2">
                                <div class="text">
                                    <p>Make sure the coupon has not expired (the expiry date is shown on the coupon). During the checkout process, enter the full promotion code and click "APPLY". You can only use one coupon code per order.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="3. Where can I check my order status?" name="3">
                                <div class="text">
                                    <p>You may check your order in your Member’s page (by clicking ‘Me’ in the front page). In the ‘My Orders’ section, you can find your products that are ‘Unpaid’, ‘Purchased’, ‘Shipped’ and ‘Returns’. You can also find your logistics information under ‘Shipped’.</p>
                                    <!-- <span>Was this article helpful?</span>
                                    <div class="btn">
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/fabulous.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                        <div class="img">
                                            <el-image :src="require('@/assets/images/index/component/stepOn.png')" fit="cover" style="cursor: pointer;"></el-image>
                                        </div>
                                    </div> -->
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.FAQ .main-collapse .el-collapse-item__header{
    font-size: 14px;
}
</style>
<style lang="scss" scoped>
@import "./css/FAQ.scss";
</style>

<script>
import FAQ from "./js/FAQ.js"

export default {
    name: "FAQ",
    components: {},
    mixins: [FAQ]
}
</script>